const dragScroll = (
  slider: HTMLElement,
  options = { activeClassList: ['select-none'] }
) => {
  let isDown = false
  var isDragged = false
  let startX: number
  let scrollLeft: number

  const { activeClassList } = options

  function mousedown(e: MouseEvent) {
    isDown = true
    slider!.classList.add(...activeClassList)
    startX = e.pageX - slider!.offsetLeft
    scrollLeft = slider!.scrollLeft
  }

  function mouseleave(e: MouseEvent) {
    isDown = false
    isDragged = false
    slider!.classList.remove(...activeClassList)
  }

  function preventClick(e: Event) {
    e.preventDefault()
    e.stopImmediatePropagation()
  }

  function mouseup(e: MouseEvent) {
    const elements = slider!.children
    if (isDragged) {
      for (let i = 0; i < elements.length; i++) {
        elements[i].addEventListener('click', preventClick)
      }
    } else {
      for (let i = 0; i < elements.length; i++) {
        elements[i].removeEventListener('click', preventClick)
      }
    }
    slider!.classList.remove(...activeClassList)
    isDown = false
    isDragged = false
  }

  function mousemove(e: MouseEvent) {
    if (!isDown) return
    isDragged = true
    e.preventDefault()
    const x = e.pageX - slider!.offsetLeft
    const walk = (x - startX) * 1.2 //scroll-fast
    slider!.scrollLeft = scrollLeft - walk
  }

  slider.addEventListener('mousedown', mousedown)
  slider.addEventListener('mouseleave', mouseleave)
  slider.addEventListener('mouseup', mouseup)
  slider.addEventListener('mousemove', mousemove)

  return () => {
    slider.removeEventListener('mousedown', mousedown)
    slider.removeEventListener('mouseleave', mouseleave)
    slider.removeEventListener('mouseup', mouseup)
    slider.removeEventListener('mousemove', mousemove)
  }
}

export default dragScroll

'use client'
import React, { useState } from 'react'
import { Image } from 'component/ui/image'
import IconVideo from 'asset/icon/video.svg'
import numberFormat from 'util/numberFormat'
import VideoPlayer from '../videoPlayer'

export enum MediaThumbnailTypeEnum {
  Video,
  Image,
  MultipleImage
}
export interface IThumbnailProp {
  url: string
  desc: string
  thumbnailType: MediaThumbnailTypeEnum
  locationName?: string
  errorVideoPoster?: string
  autoPlayVideo?: boolean
  counts?: {
    likes?: number
    replies?: number
    views?: number
    shares?: number
  }
}

const Thumbnail = ({
  url,
  desc,
  thumbnailType,
  locationName,
  errorVideoPoster,
  autoPlayVideo,
  counts
}: IThumbnailProp) => {
  const [isError, setIsError] = useState(false)

  const fallback = (
    <div className=' bg-background_still-2nd w-full h-full flex justify-center items-center'>
      <div className='i-ps-album_invisible_f  [--icon-size:24px] text-primary-on_primary' />
    </div>
  )

  const isVideo =
    autoPlayVideo && thumbnailType === MediaThumbnailTypeEnum.Video

  return (
    <>
      {!isError && thumbnailType === MediaThumbnailTypeEnum.MultipleImage && (
        <div
          className='i-ps-carousel [--icon-size:24px] absolute top-[6px] right-[6px] z-[1]  text-primary-on_primary'
          role='img'
        />
      )}
      {!isError && thumbnailType === MediaThumbnailTypeEnum.Video && (
        <div
          className='i-ps-play_f [--icon-size:24px] absolute top-[6px] right-[6px] z-[1] text-primary-on_primary'
          role='img'
        />
      )}
      {isVideo && (
        <VideoPlayer
          src={url}
          className='absolute object-cover left-0 top-0'
          loading='lazy'
          errorVideoPoster={errorVideoPoster}
          loop={true}
          muted={true}
          autoPlay
        />
      )}
      {!isVideo && (
        <Image
          src={url}
          alt={desc}
          fill={true}
          className='object-cover'
          fallback={fallback}
          onError={() => {
            setIsError(true)
          }}
          loading='lazy'
        />
      )}

      {!isError && (
        <div className='absolute bottom-0 left-0 h-1/4 w-full flex flex-col justify-end bg-gradient-post'>
          {counts && (
            <div className='flex w-full items-start self-end px-[6px] py-[3px] text-label_still-l1'>
              <IconVideo className='mr-[4px] h-[14px] w-[14px] flex-shrink-0' />
              <span className='line-clamp-2 break-words text-sm font-semibold'>
                {numberFormat(counts?.views || 0, 'compact')}
              </span>
            </div>
          )}
          {locationName && (
            <div className='flex w-full items-start self-end px-[6px] py-[3px] text-label_still-l1'>
              <span className='line-clamp-2 break-words text-sm font-semibold'>
                {locationName}
              </span>
            </div>
          )}
        </div>
      )}
    </>
  )
}

export default Thumbnail

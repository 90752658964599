import { isVideoAsset, type IAssetV2 } from 'type/asset'
import type { Nullable } from 'type/common'
import { MediaType, type IMediaV2 } from 'type/media'
import { cn, twc } from 'util/cn'
import { showMedia } from 'util/comment'
import MultiMedia from './multi-media'
import UserText from './user-text'
import type { IPlaceV2 } from 'type/geo'

const Bubble = twc.div`isolate rounded-[12px] bg-background-2nd overflow-hidden`

interface IContent {
  comment?: Nullable<string>
  user?: Nullable<{
    user_id: string
    uid: string
    name: string
    headshot?: Nullable<IAssetV2>
  }>
  place?: Nullable<IPlaceV2>
  userBarIsLink?: boolean
  commentTime: number
  media?: Nullable<IMediaV2[]>
  interactiveTools: React.ReactNode
  showMoreLabel?: boolean
  shareFrom?: Nullable<React.ReactNode>
  onMediaClick?: () => void
}

const Content = ({
  comment,
  user,
  place,
  commentTime,
  media,
  interactiveTools,
  shareFrom,
  showMoreLabel = false,
  userBarIsLink = true,
  onMediaClick
}: IContent) => {
  let _media = media?.[0]

  const showFirst = showMedia(media, 'first')
  const showSecond = showMedia(media, 'second')

  return (
    <div className={cn('min-w-0', showSecond && 'min560:w-[325px]')}>
      <div className={cn('mb-[4px] isolate overflow-hidden  w-full')}>
        {user && (
          <UserText
            comment={comment}
            name={user?.name}
            uid={user?.uid}
            commentTime={commentTime}
            showMoreLabel={showMoreLabel}
            isMore
            isLink={userBarIsLink}
          />
        )}
        {showFirst && (
          <div>
            <MultiMedia
              media={_media!}
              place={place}
              onMediaClick={onMediaClick}
            />
          </div>
        )}
      </div>
      {showSecond && (
        <Bubble className='mb-[4px]'>
          <MultiMedia media={_media!} />
        </Bubble>
      )}
      {shareFrom}
      {interactiveTools}
    </div>
  )
}

export default Content

const emptyMedia = (media: Nullable<IMediaV2>) => {
  if (!media) return true

  switch (media.type) {
    case MediaType.Link: {
      const asset = media.asset
      if (!asset || !asset.weblink_metadata) return true
      break
    }
    case MediaType.Image: {
      const asset = media.asset
      if (!asset || !asset.image_metadata) return true
      break
    }
    case MediaType.Video: {
      const asset = media.asset
      if (!asset || !asset.video_metadata) return true
      break
    }
    case MediaType.Spot: {
      const asset = media?.spot?.asset || media?.asset
      if (!asset) return true
      if (!isVideoAsset(asset)) return true
      break
    }
  }

  return false
}

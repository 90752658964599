'use client'

import { useRouter } from 'lib/next-intl'
import React, { type ReactNode, type MouseEvent } from 'react'
import { cn } from 'util/cn'

const LinkComment = ({
  children,
  className,
  isLink = true,
  link,
  debugType
}: {
  children: ReactNode
  isLink?: boolean
  className?: string
  link?: string
  debugType?: string
}) => {
  const router = useRouter()

  const handleClick = (e: MouseEvent<HTMLElement, globalThis.MouseEvent>) => {
    if (isLink && link) {
      e.stopPropagation()
      router.push(link)
    }
  }

  return (
    <article
      className={cn(isLink && 'cursor-pointer', className)}
      data-type={debugType}
      onClick={handleClick}
      role={isLink ? 'link' : 'article'}
    >
      {children}
    </article>
  )
}

export default LinkComment

'use client'
import React from 'react'

import { Image } from 'component/ui/image'
import { ScrollArea } from 'component/ui/scroll-area'
import { useDeviceStore } from 'store/server-context/device'
import type { IImageAssetV2 } from 'type/asset'
import dragScroll from 'util/dragScroll'

const getAspectRatio = (width: number, height: number) => {
  return width > height ? 4 / 3 : 3 / 4
}

const MediaImage = ({ asset }: { asset: IImageAssetV2 }) => {
  const { image_metadata } = asset

  const dragScrollRef = React.useRef<HTMLDivElement | null>(null)
  const device = useDeviceStore(state => state.device)

  React.useEffect(() => {
    if (device !== 'desktop') {
      return
    }
    const element = dragScrollRef.current
    if (!element) return

    const ds = dragScroll(element)
    return ds
  }, [device])

  if (image_metadata.length === 1) {
    const { width, height } = image_metadata[0]
    const aspectRatio = getAspectRatio(width, height)
    return (
      <div
        className='relative w-full pt-[--padding-top] rounded-xl overflow-hidden '
        style={{ '--padding-top': `${100 / aspectRatio}%` }}
      >
        <Image
          src={image_metadata[0].display_url}
          alt="Comment's image"
          fill={true}
          fallback={<div />}
        />
      </div>
    )
  }

  return (
    <ScrollArea viewportRef={dragScrollRef}>
      <div className='w-full h-[302px] overflow-x-auto flex space-x-[6px] pb-[8px] px-3 '>
        {image_metadata.map((image, index) => (
          <div
            key={index}
            className='relative w-[294px] h-[294px] overflow-hidden shrink-0 rounded-xl'
          >
            <Image
              src={image.display_url}
              alt="Comment's image"
              fill={true}
              draggable={false}
              fallback={<div />}
            />
          </div>
        ))}
      </div>
    </ScrollArea>
  )
}

export default MediaImage

export const HTTPS_REGEX = /^https?:\/\//i

const validateUrl = (url: string) => {
  let href = url

  if (!HTTPS_REGEX.test(url)) {
    href = 'https://' + url
  }
  return href
}

export default validateUrl

import React from 'react'
import type { IPlaceV2 } from 'type/geo'
import type { Nullable } from 'vitest'
import Link from '../link'

interface ICommentPlace {
  place?: Nullable<IPlaceV2>
}

function CommentPlace({ place }: ICommentPlace) {
  if (!place) return null
  return (
    <Link
      href={`/place/${place.id}`}
      onClick={e => {
        e.stopPropagation()
      }}
    >
      <div className='flex items-center px-[12px] pb-[8px]'>
        <div
          className='i-ps-pin_s mr-[4px] shrink-0 [--icon-size:16px]'
          role='img'
          aria-label='Location'
        />

        <span className='truncate'>{place.name}</span>
      </div>
    </Link>
  )
}

export default CommentPlace

'use client'
import { useTranslations } from 'next-intl'

import { HeadshotSize } from 'constant'
import {
  MediaType,
  type IMediaCommunity,
  type IMediaHashtag,
  type IMediaProfile
} from 'type/media'
import { normalizeAsset } from 'util/normalizeAsset'
import numberFormat from 'util/numberFormat'
import Headshot from '../headshot'
import Link from '../link'

const MediaShare = ({
  media
}: {
  media: IMediaCommunity | IMediaProfile | IMediaHashtag
}) => {
  const t = useTranslations()
  let asset
  let href
  let title = ''
  let description = ''
  switch (media.type) {
    // Community
    case MediaType.Community:
      asset = media.community.asset
      href = `/community/${media.community.id}`
      title = media.community.title
      description = media.community.location?.display_name || ''
      break
    // Profile
    case MediaType.Profile:
      asset = media.user?.headshot
      href = `/${media.user?.uid}`
      title = media.user?.uid || ''
      description = media.user?.name || ''
      break
    // Hashtag
    case MediaType.Hashtag:
      asset = media.hashtag.asset
      href = `/hashtag/${media.hashtag.name}`
      title = `#${media.hashtag.name}`
      description = t('count_of_spots', {
        number: numberFormat(media.hashtag.counts.spot_count, 'compact')
      })
      break
  }

  return (
    <Link className='flex items-center p-[12px] w-full' href={href}>
      <Headshot
        size={HeadshotSize.md}
        {...normalizeAsset(asset)}
        className='shrink-0 mr-[8px]'
        rounded={media.type !== 5}
      />
      <div className='grow shrink min-w-0 text-md'>
        <div className='font-semibold mb-[2px] truncate'>{title}</div>
        <div className='text-label-l2 truncate'>{description}</div>
      </div>
      <div className='ml-[12px] shrink-0 text-primary-p1 font-semibold'>
        {t('go')}
      </div>
    </Link>
  )
}

export default MediaShare

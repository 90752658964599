import type { Nullable } from 'vitest'
import type { IMediaV2 } from './media'
import type { IUserV2WithAsset } from './user'
import type { IPlaceCommentUser } from './place'
import type { IPlaceV2 } from './geo'

export enum SharedFromTypeEnum {
  profile = 'profile',
  community = 'community'
}

export enum ParentTypeEnum {
  profile = 'profile',
  community = 'community'
}

export interface ISharedFromV2 {
  type: SharedFromTypeEnum
  community: {
    id: string
    title: string
  }
  spot_id: string
}

export interface ISharedToV2 {
  communities: {
    id: string
    title: string
  }[]
}

export interface ICommentCountsV2 {
  like: number
  share: number
  comment?: number
}

export interface ICommentV2 {
  comment_id?: string
  id?: string
  community_id?: string
  text: string | null
  text_translated: string | null
  media?: IMediaV2[] | null
  owner_id: string
  counts: ICommentCountsV2
  created_at: number
  parent_type: ParentTypeEnum
  parent_id: string
  parent_title?: string
  place_id: string | null
  place?: Nullable<IPlaceV2>
  user?:
    | {
        id: string
        user_id: string
        uid: string
        headshot_id: string
        name: string
        language: string
        is_private: boolean
        is_bot: boolean
        is_block: boolean
        level: string
        follow_status: string
        created_at: number
        geo_point: { lat: string; lng: string }
        location: {
          id: string
          country_code: string
          language: string
          levels: string[]
          display_level: 1
          display_name: string
          centroid_geo_point: [Object]
        }
      }
    | Nullable<IUserV2WithAsset>
    | IPlaceCommentUser
  personal: {
    is_liked: boolean
  }
  user_is_like?: boolean
  shared_from?: ISharedFromV2 | null
  shared_to?: ISharedToV2 | null
  reference?: ICommentV2
  sub_comments?: Nullable<ICommentV2[]>
  subComments?: Nullable<ICommentV2[]>
}

export enum CommentFilterTypeEnum {
  All = '0',
  Media = '1',
  image = '2',
  video = '3'
}

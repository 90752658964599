'use client'
import useTimePass from 'hook/useTimePass'

const TimePass = (props: { timestamp: number }) => {
  const { timestamp } = props
  const passTime = useTimePass(timestamp)

  return <>{passTime}</>
}

export default TimePass

'use client'

import React from 'react'

import { likeCommentV2, unlikeCommentV2 } from 'api/like'
import { clientFetch } from 'lib/fetch/client'
import { usePopupStore } from 'store/global/popup'
import { useSessionStore } from 'store/server-context/session'
import cn, { twc } from 'util/cn'

const Button = twc.button`flex items-center tabular-nums text-label-l1 text-sm`

const wrapStopPropagation =
  <T extends (event: React.MouseEvent) => void>(func: T) =>
  (event: React.MouseEvent) => {
    event.stopPropagation()
    event.preventDefault()

    return func(event)
  }

interface IInteractiveTools {
  className?: string
  replyCount: number
  likeCount: number
  shareCount: number
  isLiked: boolean
  commentId: string
}
const InteractiveTools = ({
  className = '',
  commentId,
  isLiked,
  replyCount,
  shareCount,
  likeCount: initialLikeCount
}: IInteractiveTools) => {
  const session = useSessionStore(state => state.session)
  const showDownloadPopup = usePopupStore(state => state.showDownloadPopup)
  const showAuthPopup = usePopupStore(state => state.showAuthPopup)
  const [isLikedState, setIsLikedState] = React.useState(isLiked)
  const [likeCount, setLikeCount] = React.useState(initialLikeCount)

  const handleLikeClick = () => {
    if (session.guest) {
      showAuthPopup()
      return
    }

    const nextLikeState = !isLikedState
    setIsLikedState(nextLikeState)
    setLikeCount(nextLikeState ? likeCount + 1 : likeCount - 1)

    const likeApi = nextLikeState ? likeCommentV2 : unlikeCommentV2
    likeApi(clientFetch, commentId)
  }

  return (
    <div
      className={cn(
        'flex items-center space-x-[32px] [--icon-size:16px]',
        className
      )}
    >
      <Button
        className='flex items-center'
        onClick={wrapStopPropagation(showDownloadPopup)}
      >
        <div className='i-ps-message_s' />
        {replyCount > 0 && <div className='ml-[4px]'>{replyCount}</div>}
      </Button>

      <Button onClick={wrapStopPropagation(showDownloadPopup)}>
        <div className='i-ps-repost_s ' />
        {shareCount > 0 && <div className='ml-[4px]'>{shareCount}</div>}
      </Button>

      <Button onClick={wrapStopPropagation(handleLikeClick)}>
        <div
          className={
            isLikedState ? 'i-ps-heart_f text-third-p1' : 'i-ps-heart_s'
          }
        />
        {likeCount > 0 && <div className='ml-[4px]'>{likeCount}</div>}
      </Button>

      <Button onClick={wrapStopPropagation(showDownloadPopup)}>
        <div className='i-ps-save ' />
      </Button>

      <Button onClick={wrapStopPropagation(showDownloadPopup)}>
        <div className='i-ps-export_s ' />
      </Button>
    </div>
  )
}

export default InteractiveTools

const getYtIdFromUrl = (url: string, trimWhitespaces: boolean = true) => {
  if (trimWhitespaces) url = url.trim()

  let isYoutubeLink = false
  const expressions = [
    /^https:\/\/(?:www\.|m\.)?youtube\.com\/watch\?.*$/,
    /^https:\/\/(?:music\.)?youtube\.com\/watch\?.*$/,
    /^https:\/\/(?:www\.|m\.)?youtube\.com\/shorts\/.*$/,
    /^https:\/\/(?:www\.|m\.)?youtube(?:-nocookie)?\.com\/embed\/.*$/,
    /^https:\/\/youtu\.be\/.*$/
  ]

  for (const exp of expressions) {
    if (exp.test(url)) isYoutubeLink = true
  }

  if (!isYoutubeLink) return null

  const urlObj = new URL(url)
  if (urlObj.searchParams.has('v')) {
    return urlObj.searchParams.get('v')
  }

  const idExpressions = [
    /^https:\/\/(?:www\.|m\.)?youtube\.com\/shorts\/([_\-a-zA-Z0-9]{11}).*$/,
    /^https:\/\/(?:www\.|m\.)?youtube(?:-nocookie)?\.com\/embed\/([_\-a-zA-Z0-9]{11}).*$/,
    /^https:\/\/youtu\.be\/([_\-a-zA-Z0-9]{11}).*$/
  ]

  for (const exp of idExpressions) {
    const match = exp.exec(url)
    if (match && match[1]) return match[1]
  }

  return null
}

export default getYtIdFromUrl

'use client'
import { isVideoAsset } from 'type/asset'
import { MediaType, type IMediaV2 } from 'type/media'
import MediaImage from './media-image'
import MediaLink from './media-link'
import MediaShare from './media-share'
import MediaVideo from './media-video'
import UserText from './user-text'
import { twc } from 'react-twc'
import { useRouter } from 'lib/next-intl'
import { ParentTypeEnum } from 'type/comment'
import type { IPlaceV2 } from 'type/geo'
import type { Nullable } from 'vitest'
import CommentPlace from './comment-place'

const Bubble = twc.div`isolate rounded-[12px] bg-background-2nd overflow-hidden`
interface IMultiMedia {
  media: IMediaV2
  place?: Nullable<IPlaceV2>
  isInComment?: boolean
  onMediaClick?: () => void
}

const MultiMedia = ({
  media,
  place,
  isInComment = false,
  onMediaClick
}: IMultiMedia) => {
  const router = useRouter()
  if (!media) return null

  switch (media.type) {
    case MediaType.Link: {
      const asset = media.asset
      if (!asset) return null
      return (
        <>
          <CommentPlace place={place} />
          <MediaLink asset={asset} isInComment={isInComment} />
        </>
      )
    }
    case MediaType.Image: {
      const asset = media.asset
      if (!asset || !asset.image_metadata) return null
      return (
        <>
          <CommentPlace place={place} />
          <MediaImage asset={asset} />
        </>
      )
    }
    case MediaType.Video: {
      const asset = media.asset
      if (!asset) return null
      return (
        <>
          <CommentPlace place={place} />
          <MediaVideo
            src={asset.video_metadata.display_url}
            height={asset.video_metadata.height}
            width={asset.video_metadata.width}
            thumbnail={asset.video_metadata.thumbnail.pixel_720.url}
          />
        </>
      )
    }
    case MediaType.Spot: {
      const asset = media?.spot?.asset || media.asset
      if (!asset) return null
      if (!isVideoAsset(asset)) return null

      return (
        <>
          <UserText
            comment={media.spot.description}
            commentTime={media.spot.created_at}
            headshot={media.user?.headshot}
            name={media.user?.name}
            uid={media.user?.uid}
          />
          <CommentPlace place={place} />
          <MediaVideo
            src={asset.video_metadata.display_url}
            height={asset.video_metadata.height}
            width={asset.video_metadata.width}
            thumbnail={asset.video_metadata.thumbnail.pixel_720.url}
          />
        </>
      )
    }
    case MediaType.NewSpot: {
      const asset = media?.spot?.asset || media.asset
      if (!asset) return null
      if (!isVideoAsset(asset)) return null

      return (
        <>
          <CommentPlace place={place} />
          <MediaVideo
            src={asset.video_metadata.display_url}
            height={asset.video_metadata.height}
            width={asset.video_metadata.width}
            thumbnail={asset.video_metadata.thumbnail.pixel_720.url}
            className='rounded-xl'
            onClick={onMediaClick}
          />
        </>
      )
    }
    case MediaType.Comment: {
      const link =
        media.comment.parent_type === ParentTypeEnum.community
          ? `/community/${media.comment.parent_id}/comment/${media.comment.id}`
          : `/comment/${media.comment.id}`

      const content = (
        <Bubble>
          <UserText
            comment={media.comment.text}
            commentTime={media.comment.created_at}
            headshot={media.comment.user?.headshot}
            name={media.comment.user?.name}
            uid={media.comment.user?.uid}
            isTime={false}
            isLink
          />
          {media.comment.media && (
            <MultiMedia
              isInComment
              media={media.comment.media[0]}
              place={media.comment.place}
            />
          )}
        </Bubble>
      )

      return (
        <div
          className={media.comment.media ? '' : 'w-fit'}
          onClick={e => {
            e.stopPropagation()
            router.push(link)
          }}
        >
          {content}
        </div>
      )
    }
  }

  return <MediaShare media={media} />
}

export default MultiMedia

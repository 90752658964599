import { MediaThumbnailTypeEnum } from 'component/block/thumbnail/thumbnail'
import { isVideoAsset, isImageAsset, type IAssetV2 } from 'type/asset'
import type { ICommentV2 } from 'type/comment'
import type { Nullable } from 'type/common'
import type { ILocationV2, IPlaceV2 } from 'type/geo'
import {
  isAssetMedia,
  isCommentMedia,
  isCommunityMedia,
  isHashtagMedia,
  isInBubbleMedia,
  isProfileMedia,
  isSpotMedia,
  type IMediaV2,
  isNewSpotMedia
} from 'type/media'
import type { IUserV2 } from 'type/user'

export const getMediaAssetIdsAndProfileIds = (
  media: IMediaV2,
  mainLevel = true
): {
  assetIds: string[]
  userIds: string[]
  placeIds: string[]
  locationIds: string[]
} => {
  const assetIds: string[] = []
  const userIds: string[] = []
  const placeIds: string[] = []
  const locationIds: string[] = []
  if (isAssetMedia(media)) {
    assetIds.push(media.asset_id)
  } else if ((isSpotMedia(media) || isNewSpotMedia(media)) && media.spot) {
    media.spot.asset_id && assetIds.push(media.spot.asset_id)
    media.spot.place_id && placeIds.push(media.spot.place_id)
    media.spot.owner_id && userIds.push(media.spot.owner_id)
    if (isSpotMedia(media) && media.user?.headshot_id)
      media.user.headshot_id && assetIds.push(media.user.headshot_id)
  } else if (isHashtagMedia(media) && media.hashtag) {
    media.hashtag.thumbnail_asset_id &&
      assetIds.push(media.hashtag.thumbnail_asset_id)
  } else if (isCommunityMedia(media) && media.community) {
    media.community.asset_id && assetIds.push(media.community.asset_id)
    media.community.location_id && locationIds.push(media.community.location_id)
  } else if (isCommentMedia(media) && mainLevel && media.comment) {
    media.comment.owner_id && userIds.push(media.comment.owner_id)
    if (media.comment.media?.length) {
      const result = getMediaAssetIdsAndProfileIds(
        media.comment.media[0],
        false
      )
      assetIds.push(...result.assetIds)
      userIds.push(...result.userIds)
      placeIds.push(...result.placeIds)
      locationIds.push(...result.locationIds)
    }
  } else if (isProfileMedia(media)) {
    userIds.push(media.profile_id)
  }

  return {
    assetIds,
    userIds,
    placeIds,
    locationIds
  }
}

export const formatComment = (
  comment: ICommentV2,
  usersMap: Map<string, IUserV2>,
  assetsMap: Map<string, IAssetV2>,
  placesMap: Map<string, IPlaceV2>,
  locationsMap: Map<string, ILocationV2>
) => {
  const u =
    usersMap.get(comment.owner_id) ||
    usersMap.get(comment.user?.user_id || '') ||
    usersMap.get(comment.user?.id || '')

  const user = u ? { ...u, headshot: assetsMap.get(u.headshot_id) } : null
  const place = comment.place_id ? placesMap.get(comment.place_id) : null
  const formattedMedia = formatMedia(
    comment.media?.[0],
    usersMap,
    assetsMap,
    placesMap,
    locationsMap
  )

  return {
    ...comment,
    user,
    place,
    media: formattedMedia ? [formattedMedia] : null
  }
}

export type FullComment = ReturnType<typeof formatComment>

export const formatMedia = (
  media: IMediaV2 | null | undefined,
  usersMap: Map<string, IUserV2>,
  assetsMap: Map<string, IAssetV2>,
  placesMap: Map<string, IPlaceV2>,
  locationsMap: Map<string, ILocationV2>
): IMediaV2 | null => {
  if (!media) return null
  if (isAssetMedia(media)) {
    // @ts-expect-error this is ok
    return {
      ...media,
      asset: media?.asset_id ? assetsMap.get(media.asset_id) : null
    }
  } else if (isSpotMedia(media)) {
    if (!media.spot) return null

    const user = media?.user && {
      ...media.user,
      headshot: assetsMap.get(media?.user?.headshot_id || '')
    }
    const userInSpot = usersMap.get(media.spot.owner_id)

    return {
      ...media,
      user: userInSpot
        ? { ...userInSpot, headshot: assetsMap.get(userInSpot.headshot_id) }
        : user,
      spot: {
        ...media.spot,
        asset: media.spot?.asset_id ? assetsMap.get(media.spot.asset_id) : null,
        place: placesMap.get(media.spot.place_id)
      }
    }
  } else if (isNewSpotMedia(media)) {
    return {
      ...media,
      spot: {
        ...media.spot,
        asset: media.spot?.asset_id ? assetsMap.get(media.spot.asset_id) : null,
        place: placesMap.get(media.spot.place_id)
      }
    }
  } else if (isCommunityMedia(media)) {
    if (!media.community) return null
    return {
      ...media,
      community: {
        ...media.community,
        asset: media.community?.asset_id
          ? assetsMap.get(media.community.asset_id)
          : null,
        location: locationsMap.get(media.community.location_id)
      }
    }
  } else if (isCommentMedia(media)) {
    if (!media.comment) return null
    return {
      ...media,
      comment: formatComment(
        media.comment,
        usersMap,
        assetsMap,
        placesMap,
        locationsMap
      )
    }
  } else if (isProfileMedia(media)) {
    const user = usersMap.get(media.profile_id)

    return {
      ...media,
      user: user ? { ...user, headshot: assetsMap.get(user.headshot_id) } : null
    }
  } else if (isHashtagMedia(media)) {
    if (!media.hashtag) return null
    return {
      ...media,
      hashtag: {
        ...media.hashtag,
        asset: assetsMap.get(media.hashtag.thumbnail_asset_id || '')
      }
    }
  }

  return null
}

export const showMedia = (
  media: Nullable<IMediaV2[]>,
  position: 'first' | 'second'
) => {
  if (!media) return false
  if (media.length === 0) return false

  if (isInBubbleMedia(media[0]) && position === 'first') {
    return true
  }

  if (!isInBubbleMedia(media[0]) && position === 'second') {
    return true
  }

  return false
}

export const formatMediaThumbnailType = (
  asset?: IAssetV2 | null
): MediaThumbnailTypeEnum => {
  if (!asset) return MediaThumbnailTypeEnum.Image
  return isVideoAsset(asset)
    ? MediaThumbnailTypeEnum.Video
    : isImageAsset(asset) && asset.image_metadata.length > 1
      ? MediaThumbnailTypeEnum.MultipleImage
      : MediaThumbnailTypeEnum.Image
}

export const formatMediaAsset = (media: IMediaV2 | null | undefined) => {
  if (!media) return null
  if (isAssetMedia(media)) {
    return media.asset
  } else if (isSpotMedia(media)) {
    if (!media.spot) return null
    return media.spot.asset
  } else if (isNewSpotMedia(media)) {
    if (!media.spot) return null
    return media.spot.asset
  } else if (isCommunityMedia(media)) {
    if (!media.community) return null
    return media.community.asset
  }
}

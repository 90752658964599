'use client'

import { HeadshotSize } from 'constant'
import type { IAssetV2 } from 'type/asset'
import type { Nullable } from 'type/common'
import { cn, twc } from 'util/cn'
import { normalizeAsset } from 'util/normalizeAsset'
import Headshot from '../headshot'
import TimePass from './time-pass'
import { MoreText } from 'component/ui/more-text'
import { usePopupStore } from 'store/global/popup'
import { useRouter } from 'lib/next-intl'

const Button = twc.button`flex items-center text-label-l1 text-sm`

interface IUserText {
  comment?: Nullable<string>
  name?: string
  uid?: string
  headshot?: Nullable<IAssetV2>
  commentTime: number
  className?: string
  isTime?: boolean
  // 應用在 comment page 上
  type?: 'page' | 'comment'
  showMoreLabel?: boolean
  isMore?: boolean
  isLink?: boolean
}

const UserText = ({
  comment,
  headshot,
  name,
  uid,
  commentTime,
  className,
  isTime = true,
  type = 'comment',
  showMoreLabel,
  isMore,
  isLink = true
}: IUserText) => {
  const router = useRouter()
  const showDownloadPopup = usePopupStore(state => state.showDownloadPopup)

  return (
    <div
      className={cn('px-[12px] pt-[8px] pb-[8px] group w-full', className)}
      data-type={type}
    >
      <div className='flex items-center justify-between'>
        <div
          className='flex items-center min-w-0'
          onClick={e => {
            if (isLink && uid) {
              e.stopPropagation()
              router.push(`/${uid}`)
            }
          }}
        >
          {headshot && (
            <Headshot
              size={type === 'page' ? HeadshotSize.sm : HeadshotSize.xxs}
              {...normalizeAsset(headshot)}
              className='shrink-0 group-data-[type="page"]:mr-[8px] group-data-[type="comment"]:mr-[4px]'
            />
          )}

          <span className='truncate text-md font-semibold text-label-l1 max-w-[325px]'>
            {name}
            {uid && <span className='px-[4px] text-label-l2'>@{uid}</span>}
          </span>
          {isTime && (
            <span className='whitespace-nowrap text-sm text-label-l2'>
              <span className='px-[4px]'>·</span>
              <TimePass timestamp={commentTime} />
            </span>
          )}
        </div>

        {isMore && (
          <Button
            onClick={e => {
              e.stopPropagation()
              e.preventDefault()
              showDownloadPopup()
            }}
          >
            <div className='i-ps-more_horizontal' />
          </Button>
        )}
      </div>
      {comment && (
        <MoreText lineClamp={15} text={comment} showMoreLabel={showMoreLabel} />
      )}
    </div>
  )
}

export default UserText

'use client'
import LinkifyText from 'component/block/linkify-text'
import { useTranslations } from 'next-intl'
import React, { useEffect, useRef, useState } from 'react'
import cn from 'util/cn'

const maxLineClamp = 999

export const MoreText = ({
  lineClamp = 2,
  showMoreLabel = true,
  className,
  text
}: {
  lineClamp?: number
  moreLineClamp?: number
  showMoreLabel?: boolean
  className?: string
  text: string
}) => {
  const t = useTranslations()
  const [clamp, setClamp] = useState<number | 'none'>(2)
  const [internalShowMoreLabel, setInternalShowMoreLabel] =
    useState<boolean>(false)
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (showMoreLabel) {
      const newShowMoreButton =
        (ref.current?.clientHeight || 0) >= lineClamp * 18
      setInternalShowMoreLabel(newShowMoreButton)
    } else {
      setInternalShowMoreLabel(false)
    }
  }, [clamp, lineClamp, showMoreLabel])

  useEffect(() => {
    setClamp(lineClamp)
  }, [lineClamp, text])

  return (
    <>
      <div
        ref={ref}
        className={cn('my-2', className, 'line-clamp-2')}
        style={{
          WebkitLineClamp: clamp,
          wordBreak: 'break-word'
        }}
      >
        <LinkifyText text={text} parseHashtag parseMention parseHyperlink />
      </div>

      {clamp !== maxLineClamp && internalShowMoreLabel && (
        <button
          role='button'
          className='select-none text-md font-semibold text-label-l2'
          onClick={() => {
            setClamp(maxLineClamp)
          }}
        >
          {t('more')}
        </button>
      )}
    </>
  )
}

'use client'

import React from 'react'

import { Image } from 'component/ui/image'
import type { IWeblinkAssetV2 } from 'type/asset'
import cn, { twc } from 'util/cn'
import validateUrl from 'util/validateUrl'
import { isYtShort, getYtIdFromUrl } from 'util/youtube'
import YouTube from 'react-youtube'
import { useTranslations } from 'next-intl'

const ImageLinkText = twc.span`flex-shrink-1 min-w-0 truncate`
const ImageLinkFooter = twc.div`absolute bottom-0 left-0 right-0 p-[12px] bg-gradient-to-b from-[rgba(0,5,5,0)] to-[--overlay-thick]`
const ImageLinkWithIcon = twc.p`text-md text-label_still-l1 flex items-center min-w-0 w-full`

interface IMediaLink {
  asset: IWeblinkAssetV2
  isInComment?: boolean
}

const MediaLink = ({ asset, isInComment = false }: IMediaLink) => {
  const [ytVideoBroken, setYtVideoBroken] = React.useState(false)
  const [imgBroken, setImgBroken] = React.useState(false)
  const t = useTranslations()

  const {
    weblink_metadata: {
      display_url: displayUrl,
      title: urlTitle,
      thumbnail: thumbnail
    }
  } = asset

  const url = validateUrl(displayUrl)
  const displayText = urlTitle || displayUrl
  const ytVideoId = getYtIdFromUrl(displayUrl)

  const linkProps = {
    href: url,
    target: '_blank',
    rel: 'noopener noreferrer nofollow'
  } as const

  if (ytVideoId && !ytVideoBroken) {
    const isShort = isYtShort(displayUrl)
    const opts = {
      height: isShort ? '538' : '296',
      width: '100%'
    }
    return (
      <div className='px-3'>
        <YouTube
          videoId={ytVideoId}
          opts={opts}
          className='rounded-[12px] border-[1px] border-glass_special-separators border-solid overflow-clip'
          onError={() => {
            setYtVideoBroken(true)
          }}
        />
        <p className='text-cation text-label-l2 mb-2 mt-[6px]'>
          {t('comment.youtube_tag')}
        </p>
      </div>
    )
  }

  if (thumbnail && !imgBroken) {
    let src = ''
    if (thumbnail.pixel_720?.url) {
      src = thumbnail.pixel_720.url
    } else if (thumbnail.pixel_200?.url) {
      src = thumbnail.pixel_200.url
    } else if (thumbnail.pixel_196?.url) {
      src = thumbnail.pixel_196.url
    } else {
      src = thumbnail.pixel_64?.url || ''
    }

    if (!src) {
      setImgBroken(true)
      return
    }

    return (
      <a
        {...linkProps}
        className='relative block w-full pt-[calc(9/16_*_100%)] rounded-[12px]  border-[1px] border-glass_special-separators border-solid overflow-hidden'
      >
        <Image
          src={src}
          fill={true}
          loading='lazy'
          alt="Link's cover"
          onError={() => setImgBroken(true)}
        />
        <ImageLinkFooter>
          <ImageLinkWithIcon>
            <span
              className='[--icon-size:18px] i-ps-link mr-[4px] shrink-0'
              role='img'
              aria-label='Link'
            />
            <ImageLinkText>{displayText}</ImageLinkText>
          </ImageLinkWithIcon>
        </ImageLinkFooter>
      </a>
    )
  }

  return (
    <a
      {...linkProps}
      className={cn(
        'flex items-center mb-3 mx-3 p-3 rounded-[12px] text-body bg-background-2nd',
        isInComment ? 'bg-background-1st' : 'bg-background-2nd'
      )}
    >
      <div
        className='[--icon-size:18px] i-ps-link mr-[4px] shrink-0'
        role='img'
        aria-label='Link'
      />
      <ImageLinkText>{displayText}</ImageLinkText>
    </a>
  )
}

export default MediaLink
